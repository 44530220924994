import React from 'react';
import minite from '../../../images/Projects/minite.png';

const Minite = () => {
    return (
        <div className="col project centered">
           <a target="_blank" href="https://minite.io"><img class="screenshot" src={minite} alt="" /></a>
            <h3>Minite</h3>
            <p>A photo sharing web app for managing and backing up photos.</p>
            <p><span id="createdwith">Created with </span><span id="tools">&nbsp;Node.js, Express, MongoDB, React.js, Redux, Sass</span></p>
            <a target="_blank" href="https://minite.io"><button id="minite">LIVE VERSION</button></a>
            <a target="_blank" href="https://github.com/vickyungemach/miniteweb"><button class="hillbrewing-btn" id="github"> GITHUB</button></a>
        </div>
    )
}


export default Minite;
