import React from 'react';
import lesprit from '../../../images/Projects/lesprit.png';

const Learnit = () => {
    return (
        <div className="col project">
           <a target="_blank" href="https://www.lesprit.io"><img class="screenshot" src={lesprit} alt="" /></a>
            <h3>Lesprit</h3>
            <p>Web app for learning and memorizing vocabulary from self-curated vocabulary lists.</p>
            <p><span id="createdwith">Created with </span><span id="tools">&nbsp;Node.js, Express, MongoDB, React.js, Redux, Sass</span></p>
            <a target="_blank" href="https://www.lesprit.io"><button id="lo70s">LIVE VERSION</button></a>
            <a target="_blank" href="https://github.com/vickyungemach/lesprit"><button class="hillbrewing-btn" id="github"> GITHUB</button></a>
        </div>
    )
}


export default Learnit;
